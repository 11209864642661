import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Free Halloween Wod today at 9:00am at The Ville and 10:00am at East!
 Wear your costume, best one wins a prize.  All other class times and
open gym are cancelled.`}</strong></p>
    <p>{`Bench Press 2-2-2-2-2\\@90%1RM`}</p>
    <p>{`Pendlay Rows (Clean Grip) 2-2-2-2-2\\@90%1RM`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`2 Groups: Alive & Zombie`}</em></p>
    <p><em parentName="p">{`If you are alive you will start the wod  3 minutes before the zombies
start.  Zombies will try to catch up to the Alive group.`}</em></p>
    <p>{`Run 800M`}</p>
    <p>{`20 Double Unders`}</p>
    <p>{`40 Squats`}</p>
    <p>{`60 Situps`}</p>
    <p>{`40 Pushups`}</p>
    <p>{`20 Pullups`}</p>
    <p>{`Run 400M`}</p>
    <p><em parentName="p">{`*`}{`If you are a Zombie and fail to catch at least one person you must
perform a 25 burpee penalty.  `}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`If you are Alive and you get caught by a Zombie you must also do
the 25 burpee penalty.`}</em></p>
    <p><strong parentName="p">{`*`}{`CrossFit Kids today at Louisville East at 9:30am.  Kids, wear your
costumes!`}</strong></p>
    <p><strong parentName="p">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place on
November 5th! Come out and watch the action at The Ville starting at
9:00am. `}<a parentName="strong" {...{
          "href": "https://truegritcomp.wordpress.com"
        }}>{`https://truegritcomp.wordpress.com`}</a></strong></p>
    <p><strong parentName="p">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program starts Sunday, November 6th. For more info
contact Eric at fallscitystrength\\@gmail.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      